/**
 * An individual event item
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import react bootstrap
import Dropdown from 'react-bootstrap/Dropdown';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faFileVideo,
  faLink,
  faCalendarAlt,
  faBars,
  IconDefinition,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import { Tooltip, combineClassNames } from 'dce-reactkit';

// Import shared types
import CourseEvent from '../../shared/types/from-server/stored/CourseEvent';

// Import shared components
import CheckInIcon from '../../shared/CheckInIcon';
import TooltipButton from '../../shared/TooltipButton';

// Import shared constants
import IN_CASE_OF_EMERGENCY_SUFFIX from '../../shared/constants/IN_CASE_OF_EMERGENCY_SUFFIX';

// Import helpers
import eventTypeToIcon from '../../shared/helpers/eventTypeToIcon';
import eventTypeToTitle from '../../shared/helpers/eventTypeToTitle';

// Import style
import './EventItem.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The event
  event: CourseEvent,
  // If true, user is a learner
  isLearner: boolean,
  // Handler to call when requesting to view recordings
  onViewRecordings: () => void,
  // Handler to call when user requests the link
  onShowLink: () => void,
  // Handler to call when user wants to edit the event
  onEdit: () => void,
  // Handler to call when user wants to show attendance
  onShowAttendance: () => void,
  // Handler to call when user wants to start checking in attendees
  onStartCheckIn: () => void,
  // Handler to call when user wants to join the meeting
  onJoin: (
    opts: {
      // If true, user can join in person
      isOptionToJoinInPerson: boolean,
      // If true, user can join in Zoom
      isOptionToJoinZoom: boolean,
    },
  ) => void,
  // Integer position of the item
  position: number,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const EventItem: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    event,
    isLearner,
    onViewRecordings,
    onShowLink,
    onEdit,
    onShowAttendance,
    onStartCheckIn,
    onJoin,
    position,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  const positionClass = `EventItem-position-${position}`;

  /* ----- More Features Dropdown ----- */

  let dropdownButton: React.ReactNode;

  if (!isLearner) {
    // Dropdown items
    const dropdownItems: {
      id: string,
      ariaLabel: string,
      icon: IconDefinition,
      contents: string,
      onClick: () => void,
    }[] = [];
    // > Settings
    if (!isLearner) {
      dropdownItems.push({
        id: `EventItem-settings-${event.ihid}`,
        ariaLabel: `view or edit settings for event: ${event.name}`,
        icon: faCog,
        contents: 'Edit',
        onClick: onEdit,
      });
    }
    // > Links
    dropdownItems.push({
      id: `EventItem-link-${event.ihid}`,
      ariaLabel: `view shareable link to event: ${event.name}`,
      icon: faLink,
      contents: 'Link',
      onClick: onShowLink,
    });
    // > Attendance
    dropdownItems.push({
      id: `EventItem-show-attendance-${event.ihid}`,
      ariaLabel: `view attendance for event: ${event.name}`,
      icon: faUserCheck,
      contents: 'Attendance',
      onClick: onShowAttendance,
    });

    // Dropdown menu
    dropdownButton = (
      <Dropdown
        className="EventItem-dropdown d-inline me-1"
      >
        <Tooltip text="More Options">
          <Dropdown.Toggle
            variant="secondary"
            id={`EventItem-more-features-${event.ihid}`}
            className="btn-secondary EventItem-slim-button"
          >
            <FontAwesomeIcon
              icon={faBars}
            />
          </Dropdown.Toggle>
        </Tooltip>
        <Dropdown.Menu variant="secondary">
          {
            dropdownItems.map((item) => {
              return (
                <Dropdown.Item
                  key={item.id}
                  onClick={item.onClick}
                  aria-label={item.ariaLabel}
                  className="btn-secondary"
                >
                  <span className="EventItem-dropdown-item-icon">
                    <FontAwesomeIcon
                      icon={item.icon}
                    />
                  </span>
                  {item.contents}
                </Dropdown.Item>
              );
            })
          }
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  /* --------- Inline Buttons --------- */

  // Create link button (only outside menu if menu doesn't exist)
  let linkButton: React.ReactNode;
  if (
    // No dropdown
    !dropdownButton
  ) {
    linkButton = (
      <TooltipButton
        id={`EventItem-link-${event.ihid}`}
        className={`EventItem-slim-button ${positionClass} btn btn-secondary me-1`}
        ariaLabel={`view shareable link to event: ${event.name}`}
        tooltipText="Shareable Link"
        onClick={onShowLink}
      >
        <FontAwesomeIcon icon={faLink} />
      </TooltipButton>
    );
  }

  // Create recordings button
  let recordingsButton: React.ReactNode;
  if (event.currentZoomId && !isLearner) {
    recordingsButton = (
      <TooltipButton
        id={`EventItem-recordings-${event.ihid}`}
        className={`EventItem-slim-button ${positionClass} btn btn-secondary me-1`}
        ariaLabel={`view all recordings for event: ${event.name}`}
        tooltipText="View/Publish Recordings"
        onClick={onViewRecordings}
      >
        <FontAwesomeIcon icon={faFileVideo} />
      </TooltipButton>
    );
  }

  // Create check-in button
  let checkInButton: React.ReactNode;
  if (event.isHeldInPerson && !isLearner) {
    const contents = (
      <div>
        <CheckInIcon
          sizeEm={1.2}
        />
        CheckIn QR
      </div>
    );
    checkInButton = (
      <TooltipButton
        id={`EventItem-join-${event.ihid}`}
        className={`EventItem-slim-button ${positionClass} btn btn-dark me-1`}
        ariaLabel={`start check-in for event: ${event.name}`}
        tooltipText="Get CheckIn QR Code for In-Person Students"
        onClick={onStartCheckIn}
      >
        {contents}
      </TooltipButton>
    );
  }

  // Create join button
  let joinButton: React.ReactNode;
  const isOptionToJoinZoom = !!event.currentZoomId;
  const isOptionToJoinInPerson = !!(
    isLearner
    && event.isHeldInPerson
  );
  if (isOptionToJoinZoom || isOptionToJoinInPerson) {
    // Create badges
    const badges: React.ReactNode[] = [];
    if (isOptionToJoinZoom) {
      badges.push(
        <div
          key="zoom"
          className="EventItem-join-badge badge text-bg-primary"
        >
          Zoom
        </div>,
      );
    }
    if (isOptionToJoinInPerson) {
      badges.push(
        <div
          key="in-person"
          className="EventItem-join-badge badge text-bg-warning"
        >
          In-Person
        </div>,
      );
    }
    const badgeContainer = (
      <div
        className={combineClassNames([
          'EventItem-join-badges',
          (badges.length === 2) ? 'EventItem-join-badges-hybrid' : undefined,
        ])}
      >
        {badges}
      </div>
    );

    // Determine tooltip text
    let tooltipText: string;
    if (isOptionToJoinZoom && isOptionToJoinInPerson) {
      tooltipText = 'Join Zoom or in Person';
    } else if (isOptionToJoinZoom) {
      tooltipText = 'Join in Zoom';
    } else {
      tooltipText = 'Join in Person';
    }

    // Create contents
    const contents = (
      <div className="fw-bold d-flex flex-row align-items-center">
        Join
        {badgeContainer}
      </div>
    );
    joinButton = (
      <TooltipButton
        id={`EventItem-join-${event.ihid}`}
        className={`EventItem-slim-button ${positionClass} btn btn-dark me-1`}
        ariaLabel={`join event: ${event.name}`}
        tooltipText={tooltipText}
        onClick={() => {
          onJoin({
            isOptionToJoinInPerson,
            isOptionToJoinZoom,
          });
        }}
      >
        {contents}
      </TooltipButton>
    );
  }

  // Choose the event icon based on the type
  const eventIcon = eventTypeToIcon(event.type);

  // Divide up event name
  const rawEventName = event.name.trim();
  const isEmergencyEvent = (
    rawEventName
      .toLowerCase()
      .endsWith(IN_CASE_OF_EMERGENCY_SUFFIX)
  );
  const eventNameText = (
    isEmergencyEvent
      ? rawEventName.substring(
        0,
        rawEventName.length - IN_CASE_OF_EMERGENCY_SUFFIX.length,
      ).trim()
      : rawEventName
  );
  const eventName = (
    <>
      {eventNameText}
      {isEmergencyEvent && (
        <span
          className="font-weight-normal"
          style={{
            letterSpacing: '-0.05em',
          }}
        >
          {' '}
          (in case of emergency)
        </span>
      )}
    </>
  );

  // Create title area
  const title = (
    <h3
      className={`EventItem-h3 ${positionClass} m-0 p-0`}
      style={{
        whiteSpace: 'nowrap',
      }}
      title={event.name}
    >
      {/* Show icon on larger screens */}
      <div
        className="text-center d-none d-md-inline-block ps-1"
        style={{ minWidth: '3rem' }}
        title={`This event's type is: "${eventTypeToTitle(event.type)}"`}
      >
        <FontAwesomeIcon
          icon={eventIcon || faCalendarAlt}
          className="me-1"
        />
      </div>
      {/* Smaller font on small screens */}
      <span
        className={`EventItem-title EventItem-title-${event.ihid} ${positionClass} d-inline d-md-none`}
        style={{ fontSize: '80%' }}
      >
        {eventName}
      </span>
      <span className={`EventItem-title EventItem-title-${event.ihid} ${positionClass} d-none d-md-inline`}>
        {eventName}
      </span>
    </h3>
  );

  // Create button area
  const buttons = (
    <span>
      {dropdownButton}
      {recordingsButton}
      {linkButton}
      {checkInButton}
      {joinButton}
    </span>
  );

  return (
    <div className="alert alert-dark ps-1 pe-1 pt-1 pb-2 mb-2">
      {/* Spacer that Appears for md+ Screens */}
      <div className="d-none d-md-block mt-1" />

      {/* Flex Container */}
      <div className="EventItem-container d-flex">
        {/* Title */}
        <div
          className={`EventItem-title-container ${positionClass} d-flex align-items-center flex-grow-1`}
          style={{ overflow: 'hidden' }}
        >
          {title}

          {/* Fader to cover end of text (small screens) */}
          <div className="EventItem-text-fader-outer-small d-inline-block d-md-none">
            <div className="EventItem-text-fader-inner-small EventItem-text-fader-inner-small-dark" />
          </div>
        </div>
        {/* Buttons */}
        <div
          className="EventItem-button-container"
          style={{ whiteSpace: 'nowrap' }}
        >
          {/* Fader to cover end of text (large screens) */}
          <div className="EventItem-text-fader-outer d-none d-md-inline-block">
            <div className="EventItem-text-fader-inner EventItem-text-fader-inner-dark" />
          </div>

          {/* Buttons */}
          {buttons}
        </div>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default EventItem;
