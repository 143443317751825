// Import React
import React from 'react';

// Import dce-reactkit
import {
  ButtonInputGroup,
  Drawer,
  RadioButton,
  Variant,
  DynamicWord,
} from 'dce-reactkit';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserGroup } from '@fortawesome/free-solid-svg-icons';

// Import shared types
import AssignToGroups from '../../../../shared/types/from-server/stored/CourseEvent/AssignToGroups';

// Import shared components
import CheckInIcon from '../../../../shared/CheckInIcon';

// Import shared constants
import MIN_EVENT_FORM_LABEL_WIDTH from '../../../../shared/constants/MIN_EVENT_FORM_LABEL_WIDTH';

// Import style
import './InPersonConfig.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Whether the event is held in person
  eventIsHeldInPerson?: boolean,
  /**
   * Set whether the event is held in person
   * @param eventIsHeldInPerson if true, the event is held in person
   */
  setEventIsHeldInPerson: (eventIsHeldInPerson: boolean) => void,
  // Whether to 'Always', 'Never', or 'Ask' to assign groups
  assignToGroups?: AssignToGroups,
  /**
   * Set whether to 'Always', 'Never', or 'Ask' to assign groups
   * @param assignToGroups whether to 'Always', 'Never', or 'Ask' to assign
   *   groups
   */
  // Handler for setting assignToGroups
  setAssignToGroups: (assignToGroups: AssignToGroups) => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Enable the grouping feature?
const enableGroupingFeature = true;

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const InPersonConfig: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    eventIsHeldInPerson,
    setEventIsHeldInPerson,
    assignToGroups = AssignToGroups.Never,
    setAssignToGroups,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Render contents
  let drawerContents: React.ReactNode;
  if (eventIsHeldInPerson) {
    drawerContents = (
      <div className="text-dark m-0 text-center">
        <div className="text-start">
          <div className="fw-bold">
            How to take attendance:
          </div>
          {/* Row container */}
          <div
            className="InPersonConfig-instructions-container d-flex flex-row gap-2"
            style={{
              alignItems: 'stretch',
            }}
          >
            {/* Step 1 */}
            <div
              className="card flex-grow-1"
              style={{
                flexBasis: '1',
              }}
            >
              <div className="card-body">
                <h5 className="card-title d-flex align-items-center">
                  <span
                    className="badge text-dark pt-1 pb-1 ps-2 pe-2 me-1"
                    style={{ backgroundColor: '#ddd' }}
                  >
                    1
                  </span>
                  <span>
                    Start CheckIn
                  </span>
                </h5>
                <p className="card-text">
                  {DynamicWord.ClickCapitalized}
                  {' '}
                  this event&apos;s
                  {' '}
                  <span className="sr-only">
                    check in
                  </span>
                  <span style={{ pointerEvents: 'none' }}>
                    <button
                      type="button"
                      className="btn btn-dark btn-sm pt-1 pb-1"
                    >
                      <CheckInIcon
                        sizeEm={1.2}
                      />
                      CheckIn QR
                    </button>
                  </span>
                  {' '}
                  button and a QR code will appear
                </p>
              </div>
            </div>
            {/* Step 2 */}
            <div
              className="card flex-grow-1"
              style={{
                flexBasis: '1',
              }}
            >
              <div className="card-body">
                <h5 className="card-title d-flex align-items-center">
                  <span
                    className="badge text-dark pt-1 pb-1 ps-2 pe-2 me-1"
                    style={{ backgroundColor: '#ddd' }}
                  >
                    2
                  </span>
                  <span>
                    Students Scan QR
                  </span>
                </h5>
                <p className="card-text">
                  Attendance is recorded as students scan the QR code
                </p>
              </div>
            </div>
            {/* Step 3 */}
            <div
              className="card flex-grow-1"
              style={{
                flexBasis: '1',
              }}
            >
              <div className="card-body">
                <h5 className="card-title d-flex align-items-center">
                  <span
                    className="badge text-dark pt-1 pb-1 ps-2 pe-2 me-1"
                    style={{ backgroundColor: '#ddd' }}
                  >
                    3
                  </span>
                  <span>
                    Done!
                  </span>
                </h5>
                <p className="card-text">
                  To view attendance,
                  {' '}
                  {DynamicWord.Click}
                  {' '}
                  the
                  {' '}
                  <span className="sr-only">
                    more options
                  </span>
                  <span style={{ pointerEvents: 'none' }}>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm pt-0 pb-0 ps-1 pe-1"
                      aria-label="visual of the more options button, not interactive"
                    >
                      <FontAwesomeIcon
                        icon={faBars}
                      />
                    </button>
                  </span>
                  {' '}
                  button and select &quot;Attendance&quot;
                </p>
              </div>
            </div>
          </div>
        </div>

        {enableGroupingFeature && (
          <>
            <div className="text-start mt-3">
              <div className="fw-bold">
                Group students as they enter:
              </div>
              <div className="mb-2">
                You can automatically divvy students up into groups.
                When students check in, they&apos;ll be shown their group
                number.
                Below, choose how often you&apos;ll want to use this feature:
              </div>
            </div>
            <ButtonInputGroup
              label={(
                <span>
                  <FontAwesomeIcon
                    icon={faUserGroup}
                    className="me-1"
                  />
                  Grouping
                </span>
              )}
              minLabelWidth={MIN_EVENT_FORM_LABEL_WIDTH}
              noMarginOnBottom
              wrapButtonsAndAddGaps
            >
              {/* Ask to assign people to groups */}
              <RadioButton
                id="InPersonConfig-ask-assign-to-groups-button"
                ariaLabel="Ask each day"
                selected={assignToGroups === AssignToGroups.Ask}
                selectedVariant={Variant.Warning}
                small
                onSelected={() => {
                  // Skip if already done
                  if (assignToGroups === AssignToGroups.Ask) {
                    return;
                  }

                  // Set event to not be held in person
                  setAssignToGroups(AssignToGroups.Ask);
                }}
                text={(
                  <span>
                    <span className="fw-bold">
                      Ask
                    </span>
                    &nbsp;each day
                  </span>
                )}
              />
              {/* Always put people into groups */}
              <RadioButton
                id="InPersonConfig-always-assign-to-groups-button"
                ariaLabel="Always put people into groups"
                selected={assignToGroups === AssignToGroups.Always}
                selectedVariant={Variant.Warning}
                small
                onSelected={() => {
                  // Skip if already attached
                  if (assignToGroups === AssignToGroups.Always) {
                    return;
                  }

                  // Set event to be held in person
                  setAssignToGroups(AssignToGroups.Always);
                }}
                text={(
                  <span>
                    <span className="fw-bold">
                      Always
                    </span>
                    &nbsp;assign people to groups
                  </span>
                )}
              />
              {/* Never put people into groups */}
              <RadioButton
                id="InPersonConfig-never-assign-to-groups-button"
                ariaLabel="Never put people into groups"
                selected={assignToGroups === AssignToGroups.Never}
                selectedVariant={Variant.Warning}
                small
                onSelected={() => {
                  // Skip if already attached
                  if (assignToGroups === AssignToGroups.Never) {
                    return;
                  }

                  // Set event to be held in person
                  setAssignToGroups(AssignToGroups.Never);
                }}
                text={(
                  <span>
                    <span className="fw-bold">
                      Never
                    </span>
                    &nbsp;assign people to groups
                  </span>
                )}
              />
            </ButtonInputGroup>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="mb-3">
      <ButtonInputGroup
        label={(
          <span>
            <CheckInIcon
              sizeEm={1.3}
              lightMode
            />
            In-Person
          </span>
        )}
        minLabelWidth={MIN_EVENT_FORM_LABEL_WIDTH}
        noMarginOnBottom={!!eventIsHeldInPerson}
        wrapButtonsAndAddGaps
      >
        {/* Held In-Person button */}
        <RadioButton
          id="InPersonConfig-held-in-person-button"
          ariaLabel="Held in-person"
          selected={!!eventIsHeldInPerson}
          selectedVariant={Variant.Warning}
          small
          onSelected={() => {
            // Skip if already attached
            if (eventIsHeldInPerson) {
              return;
            }

            // Set event to be held in person
            setEventIsHeldInPerson(true);
          }}
          text="Held In-Person"
        />

        {/* Not held In-Person button */}
        <RadioButton
          id="InPersonConfig-not-held-in-person-button"
          ariaLabel="Not held in-person"
          selected={!eventIsHeldInPerson}
          selectedVariant={Variant.Warning}
          small
          onSelected={() => {
            // Skip if already done
            if (!eventIsHeldInPerson) {
              return;
            }

            // Set event to not be held in person
            setEventIsHeldInPerson(false);
          }}
          text="Not Held In-Person"
        />
      </ButtonInputGroup>

      {/* Drawer for Zoom settings */}
      {drawerContents && (
        <Drawer
          grayBackground
        >
          {drawerContents}
        </Drawer>
      )}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default InPersonConfig;
