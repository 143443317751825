/**
 * Popup to ask user if they want to host the meeting then directs them
 *   to the proper link
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChalkboardTeacher,
  faVideo,
  faQrcode,
  faUniversity,
  faUserAlt,
  faMobileButton,
  faCircle,
  faHandBackFist,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  Modal,
  LoadingSpinner,
  visitServerEndpoint,
  showFatalError,
  ModalType,
} from 'dce-reactkit';

// Import shared types
import CourseEvent from '../../../shared/types/from-server/stored/CourseEvent';

// Import shared components
import ClaimAccountNotice from '../../../shared/ClaimAccountNotice';

// Import style
import './ConfigureJoinSubpanel.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Handler to call when user cancels the join
  onCancel: () => void,
  // Id of the course
  courseId: number,
  // The event to join
  event: CourseEvent,
  // If true, user is a learner
  isLearner: boolean,
  // If true, show the role chooser
  showRoleChooser: boolean,
  // If true, show the modality chooser
  showModalityChooser: boolean,
  // Handler to call when user opts to join the webinar as a panelist
  onJoinZoomAsPanelist: (startURL: string) => void,
  // Handler to call when user opts to join the meeting as a participant
  onJoinZoomAsParticipant: () => void,
  /**
   * Handler to call when user opts to join the meeting as a host
   * @param startURL the url to start the meeting
   */
  onJoinZoomAsHost: (startURL: string) => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Modality chooser
  ModalityChooser = 'ModalityChooser',
  // Role chooser
  RoleChooser = 'RoleChooser',
  // Claim account notice
  ClaimAccountNoticePanel = 'ClaimAccountNoticePanel',
  // Join in-person notice
  JoinInPersonNotice = 'JoinInPersonNotice',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.ModalityChooser,
  }
  | {
    // Current view
    view: View.RoleChooser,
  }
  | {
    // Current view
    view: View.ClaimAccountNoticePanel,
  }
  | {
    // Current view
    view: View.JoinInPersonNotice,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show loading indicator
  StartLoading = 'StartLoading',
  // Show modality chooser
  ShowModalityChooser = 'ShowModalityChooser',
  // Show role chooser
  ShowRoleChooser = 'ShowRoleChooser',
  // Show claim account notice
  ShowClaimAccountNotice = 'ShowClaimAccountNotice',
  // Show join in-person notice
  ShowJoinInPersonNotice = 'ShowJoinInPersonNotice',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.StartLoading
      | ActionType.ShowModalityChooser
      | ActionType.ShowRoleChooser
      | ActionType.ShowClaimAccountNotice
      | ActionType.ShowJoinInPersonNotice
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.StartLoading: {
      return {
        view: View.Loading,
      };
    }
    case ActionType.ShowModalityChooser: {
      return {
        view: View.ModalityChooser,
      };
    }
    case ActionType.ShowRoleChooser: {
      return {
        view: View.RoleChooser,
      };
    }
    case ActionType.ShowClaimAccountNotice: {
      return {
        view: View.ClaimAccountNoticePanel,
      };
    }
    case ActionType.ShowJoinInPersonNotice: {
      return {
        view: View.JoinInPersonNotice,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ConfigureJoinSubpanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    courseId,
    onCancel,
    event,
    isLearner,
    showRoleChooser,
    showModalityChooser,
    onJoinZoomAsParticipant,
    onJoinZoomAsHost,
    onJoinZoomAsPanelist,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  let initialState: State;
  if (showModalityChooser) {
    initialState = {
      view: View.ModalityChooser,
    };
  } else if (showRoleChooser) {
    initialState = {
      view: View.RoleChooser,
    };
  } else {
    initialState = {
      view: View.JoinInPersonNotice,
    };
  }

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Get the startURL for the host
   * @author Gabe Abrams
   */
  const getStartURL = async () => {
    // Start loading
    dispatch({
      type: ActionType.StartLoading,
    });

    // Get the start URL (add as alt-host if needed, log in, get URL)
    try {
      const startURL = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events/${event.ihid}/start_url`,
        method: 'GET',
      });

      // Alert parent that user wants to join
      if (event.isWebinar) {
        onJoinZoomAsPanelist(startURL);
      } else {
        onJoinZoomAsHost(startURL);
      }
    } catch (err) {
      // Handle user not found error
      if ((err as any).code && (err as any).code === 'ZOOM404-1113') {
        return dispatch({
          type: ActionType.ShowClaimAccountNotice,
        });
      }

      // Handle user not found during ZAK error
      if ((err as any).code && (err as any).code === 'ZOOM404-1001') {
        return dispatch({
          type: ActionType.ShowClaimAccountNotice,
        });
      }

      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;
  let modalTitle: string = 'How do you want to join?';
  let modalType = ModalType.NoButtons;

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    modalTitle = 'Loading';
    body = (
      <LoadingSpinner />
    );
  }

  /* -------- Modality Chooser -------- */

  if (view === View.ModalityChooser) {
    modalTitle = 'Hybrid Event';
    body = (
      <div>
        <p className="lead fw-normal mb-2">
          This event is held both in-person and in Zoom.
          How are you joining?
        </p>
        <div className="ConfigureJoinSubpanel-choice-container">
          <button
            type="button"
            id="ConfigureJoinSubpanel-choice-button-join-in-person"
            className="ConfigureJoinSubpanel-choice-button btn btn-warning"
            aria-label="join in person"
            onClick={() => {
              dispatch({
                type: ActionType.ShowJoinInPersonNotice,
              });
            }}
          >
            <FontAwesomeIcon
              icon={faUniversity}
              className="me-1"
            />
            In-Person
          </button>
          <button
            type="button"
            id="ConfigureJoinSubpanel-choice-button-join-in-zoom"
            className="ConfigureJoinSubpanel-choice-button btn btn-primary"
            aria-label="join in Zoom"
            onClick={() => {
              if (showRoleChooser) {
                // Ask user what role they want to join as
                dispatch({
                  type: ActionType.ShowRoleChooser,
                });
              } else {
                // Join as participant
                onJoinZoomAsParticipant();
              }
            }}
          >
            <FontAwesomeIcon
              icon={faVideo}
              className="me-1"
            />
            Zoom
          </button>
        </div>
      </div>
    );
  }

  /* ---------- Role Chooser ---------- */

  if (view === View.RoleChooser) {
    modalTitle = 'How do you want to join?';
    body = (
      <div>
        <div className="ConfigureJoinSubpanel-choice-container">
          <button
            key="participant-button"
            type="button"
            id="ConfigureJoinSubpanel-choice-button ConfigureJoinSubpanel-join-as-participant-button"
            className="ConfigureJoinSubpanel-participant-button ConfigureJoinSubpanel-show-text-on-hover btn btn-dark btn-lg"
            aria-label="continue to Zoom as participant"
            onClick={onJoinZoomAsParticipant}
          >
            <FontAwesomeIcon
              icon={faUserAlt}
              className="me-2"
            />
            <strong>
              Participant
            </strong>

            {/* Notice for joining as a participant */}
            <div className="ConfigureJoinSubpanel-text-to-show-on-hover small">
              Sign out of the Zoom app first!
            </div>
          </button>
          <button
            key="host-button"
            type="button"
            id={(
              event.isWebinar
                ? 'ConfigureJoinSubpanel-i-am-the-panelist-button'
                : 'ConfigureJoinSubpanel-i-am-the-host-button'
            )}
            className="ConfigureJoinSubpanel-choice-button ConfigureJoinSubpanel-i-am-the-host-button ConfigureJoinSubpanel-show-text-on-hover btn btn-dark btn-lg"
            aria-label={`join the ${event.isWebinar ? 'webinar as a panelist' : 'meeting as a host'}`}
            onClick={() => {
              getStartURL();
            }}
          >
            <FontAwesomeIcon
              icon={faChalkboardTeacher}
              className="me-2"
            />
            <strong>
              {event.isWebinar ? 'Panelist' : 'Host'}
            </strong>

            {/* Notice for joining as a host */}
            <div className="ConfigureJoinSubpanel-text-to-show-on-hover small">
              {
                event.isWebinar
                  ? 'You may need to wait for staff to let you in'
                  : 'If not the first host, you\'ll be a co-host'
              }
            </div>
          </button>
        </div>

        {/* Note */}
        <div className="text-start mt-2">
          <strong>
            Note:&nbsp;
          </strong>
          Students can only join as participants.
        </div>
      </div>
    );
  }

  /* ------ Claim Account Notice ------ */

  if (view === View.ClaimAccountNoticePanel) {
    modalTitle = 'Claim Account';
    body = (
      <ClaimAccountNotice
        isLearner={isLearner}
        onTryAgain={() => {
          // Retry getting start link
          getStartURL();
        }}
        onCancel={onCancel}
      />
    );
  }

  /* ------ Join In-Person Notice ----- */

  if (view === View.JoinInPersonNotice) {
    // Create the "scan" graphic
    const graphic = (
      <div className="ConfigureJoinSubpanel-in-person-graphic-container">
        <div className="ConfigureJoinSubpanel-in-person-graphic-phone">
          <div className="ConfigureJoinSubpanel-in-person-graphic-phone-base-container">
            <FontAwesomeIcon
              icon={faMobileButton}
              className="ConfigureJoinSubpanel-in-person-graphic-phone-base"
            />
          </div>
          <div className="ConfigureJoinSubpanel-in-person-graphic-phone-blank-screen" />
          <div className="ConfigureJoinSubpanel-in-person-graphic-phone-shutter-button">
            <FontAwesomeIcon
              icon={faCircle}
            />
          </div>
          <div className="ConfigureJoinSubpanel-in-person-graphic-phone-check-in-onscreen">
            <div className="ConfigureJoinSubpanel-in-person-graphic-check-in bg-danger">
              <div className="ConfigureJoinSubpanel-in-person-graphic-check-in-text">
                CheckIn
              </div>
              <FontAwesomeIcon
                icon={faQrcode}
                className="ConfigureJoinSubpanel-in-person-graphic-qr-code"
              />
            </div>
          </div>
        </div>
        <div className="ConfigureJoinSubpanel-in-person-graphic-phone-hand">
          <FontAwesomeIcon
            icon={faHandBackFist}
          />
        </div>
        <div className="ConfigureJoinSubpanel-in-person-graphic-phone-thumb" />
        <div className="ConfigureJoinSubpanel-in-person-graphic-phone-check-in-behind">
          <div className="ConfigureJoinSubpanel-in-person-graphic-check-in bg-danger">
            <div className="ConfigureJoinSubpanel-in-person-graphic-check-in-text">
              CheckIn
            </div>
            <FontAwesomeIcon
              icon={faQrcode}
              className="ConfigureJoinSubpanel-in-person-graphic-qr-code"
            />
          </div>
        </div>
      </div>
    );

    // Create the text content
    if (isLearner) {
      modalTitle = 'How to Join In Person';
      modalType = ModalType.Okay;
      body = (
        <>
          {graphic}
          <p className="lead text-center m-0 fw-normal">
            Scan the
            {' '}
            <strong>
              QR Code
            </strong>
            {' '}
            to check in when you arrive.
          </p>
          <p className="lead text-center m-0 fw-normal">
            Can&apos;t find it? Ask the event host.
          </p>
        </>
      );
    } else {
      modalTitle = 'How to Take In-Person Attendance';
      modalType = ModalType.Okay;
      body = (
        <div>
          <p className="lead text-center m-0 fw-normal">
            Once you arrive in person
          </p>
        </div>
      );
    }
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Render full UI
  return (
    <Modal
      key="main-modal"
      title={modalTitle}
      onClose={onCancel}
      type={modalType}
    >
      {body}
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ConfigureJoinSubpanel;
