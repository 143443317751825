/**
 * Attach an existing zoom meeting or create a new zoom meeting/webinar then
 *   return the results
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faChalkboardTeacher,
  faStar,
  faSave,
  faVideo,
  faHeadset,
} from '@fortawesome/free-solid-svg-icons';

// Import zaccl
import ZoomWebinar from 'zaccl/lib/types/ZoomWebinar';
import ZoomMeeting from 'zaccl/lib/types/ZoomMeeting';

// Import dce-reactkit
import {
  LoadingSpinner,
  Modal,
  ModalSize,
  showFatalError,
  visitServerEndpoint,
  alert,
} from 'dce-reactkit';

// Import shared types
import CourseEvent from '../../../../shared/types/from-server/stored/CourseEvent';
import KeyZoomMeetingOrWebinarInfo from '../../../../shared/types/from-server/KeyZoomMeetingOrWebinarInfo';
import ZoomUserAccountStatus from '../../../../shared/types/from-server/ZoomUserAccountStatus';
import School from '../../../../shared/types/from-server/School';

// Import helpers
import createRecommendedZoomMeeting from '../../../../shared/helpers/createRecommendedZoomMeeting';
import isEmail from '../../../../shared/helpers/isEmail';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The id of the current course
  courseId: number,
  // The name of the current course
  courseName: string,
  // Name of the current term
  termName: string,
  // The meeting school tracking code
  school: School,
  // Handler to call when canceling
  onCancel: () => void,
  /**
   * Handler to call when done attaching
   * @param zoomInfo important Zoom information
   */
  onDone: (zoomInfo: KeyZoomMeetingOrWebinarInfo) => void,
  // The name of the event
  eventName: string,
  // List of other events
  otherEvents: CourseEvent[],
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Choose attachment method
  ChooseAttachmentMethod = 'ChooseAttachmentMethod',
  // Manually import
  ManuallyImport = 'ManuallyImport',
  // Create recommended settings meeting
  CreateRecommendedMeeting = 'CreateRecommendedMeeting',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.ChooseAttachmentMethod,
  }
  | {
    // Current view
    view: View.ManuallyImport,
    // If true, manually importing a webinar
    isWebinar: boolean,
    // Current text of the zoom id field
    zoomIdText: string,
  }
  | {
    // Current view
    view: View.CreateRecommendedMeeting,
    // The zoom host email for the new meeting
    hostEmail: string,
  }
  | {
    // Current view
    view: View.Loading,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Choose to manually import
  ChooseManuallyImport = 'ChooseManuallyImport',
  // Choose to create a recommended settings meeting
  ChooseCreateRecommendedMeeting = 'ChooseCreateRecommendedMeeting',
  // Update the zoom id text
  UpdateZoomIdText = 'UpdateZoomIdText',
  // Update the host email
  UpdateHostEmail = 'UpdateHostEmail',
  // Start loading
  StartLoading = 'StartLoading',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.ChooseManuallyImport,
    // If true, manually importing a webinar
    isWebinar: boolean,
  }
  | {
    // Action type
    type: ActionType.UpdateZoomIdText,
    // New text of the zoom id field
    zoomIdText: string,
  }
  | {
    // Action type
    type: ActionType.UpdateHostEmail,
    // New host email
    hostEmail: string,
  }
  | {
    // Action type
    type: (
      | ActionType.StartLoading
      | ActionType.ChooseCreateRecommendedMeeting
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ChooseCreateRecommendedMeeting: {
      return {
        ...state,
        view: View.CreateRecommendedMeeting,
        hostEmail: '',
      };
    }
    case ActionType.ChooseManuallyImport: {
      return {
        ...state,
        view: View.ManuallyImport,
        isWebinar: action.isWebinar,
        zoomIdText: '',
      };
    }
    case ActionType.UpdateZoomIdText: {
      if (state.view !== View.ManuallyImport) {
        return state;
      }

      return {
        ...state,
        zoomIdText: action.zoomIdText,
      };
    }
    case ActionType.UpdateHostEmail: {
      if (state.view !== View.CreateRecommendedMeeting) {
        return state;
      }

      return {
        ...state,
        hostEmail: action.hostEmail,
      };
    }
    case ActionType.StartLoading: {
      return {
        view: View.Loading,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* --------------------------- Static Helpers --------------------------- */
/*------------------------------------------------------------------------*/

/**
 * Parse the meetingId text given by a user
 * @author Gabe Abrams
 * @param meetingIdText text of the meetingId input field
 * @returns meetingId
 */
const parseMeetingId = (meetingIdText: string): number | null => {
  // Just get the numbers in the meeting text (remove hyphens and spaces)
  const justNumbers = (
    meetingIdText
      .trim()
      .replace(/-/g, '')
      .replace(/ /g, '')
  );

  // Check for an invalid number
  if (Number.isNaN(Number.parseInt(justNumbers, 10))) {
    return null;
  }

  // Parse the number
  return Number.parseInt(justNumbers, 10);
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AddZoomMeetingOrWebinarModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    courseId,
    courseName,
    termName,
    school,
    onCancel,
    onDone,
    eventName,
    otherEvents,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.ChooseAttachmentMethod,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Create recommended settings meeting
   * @author Gabe Abrams
   */
  const createRecommended = async () => {
    // Skip if in wrong view
    if (view !== View.CreateRecommendedMeeting) {
      return;
    }

    // Show loading indicator
    dispatch({
      type: ActionType.StartLoading,
    });

    // Create the meeting
    try {
      const keyMeetingInfo = await createRecommendedZoomMeeting({
        courseId,
        courseName,
        school,
        eventName,
        termName,
        hostEmail: (
          state.hostEmail.trim().length > 0
            ? state.hostEmail
            : undefined
        ),
      });

      // Finish and return to parent
      onDone(keyMeetingInfo);
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Attach an existing meeting or webinar
   * @author Gabe Abrams
   */
  const attachExisting = async () => {
    // Skip if in wrong view
    if (view !== View.ManuallyImport) {
      return;
    }

    // Destructure state
    const {
      isWebinar,
      zoomIdText,
    } = state;

    // Process meeting/webinar id
    const currentZoomId = parseMeetingId(zoomIdText);

    // Skip if invalid id
    if (!currentZoomId) {
      return;
    }

    // Load the meeting/webinar info
    try {
      if (isWebinar) {
        // Load the webinar info
        const webinar: ZoomWebinar = await visitServerEndpoint({
          path: `/api/admin/courses/${courseId}/webinars/${currentZoomId}`,
        });

        // Finish and return to parent
        return onDone({
          status: ZoomUserAccountStatus.Claimed,
          zoomId: currentZoomId,
          joinURL: webinar.join_url,
          hostId: webinar.host_id,
          hostEmail: undefined,
          password: webinar.password,
          isWebinar: true,
        });
      }

      // Load the meeting info
      const meeting: ZoomMeeting = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/meetings/${currentZoomId}`,
      });

      // Extract data from the meeting
      const autoRecordOn = (meeting.settings.auto_recording === 'cloud');
      const waitingRoomOn = !!meeting.settings.waiting_room;

      // Finish and return to parent
      return onDone({
        status: ZoomUserAccountStatus.Claimed,
        zoomId: currentZoomId,
        joinURL: meeting.join_url,
        hostId: meeting.host_id,
        hostEmail: undefined,
        password: meeting.password,
        isWebinar: false,
        autoRecordOn,
        waitingRoomOn,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;
  let title: string = 'Add Zoom';

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Create body
    body = (
      <div className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  /* ---- Choose Attachment Method ---- */

  if (view === View.ChooseAttachmentMethod) {
    title = 'How do you want to add Zoom?';
    body = (
      <div className="d-grid gap-2">
        {/* Recommended Setup Button */}
        <button
          id="AttachZoomMeetingOrWebinarModal-recommended-setup-button"
          type="button"
          className="btn btn-dark btn-lg"
          aria-label="create a new zoom meeting"
          onClick={() => {
            dispatch({
              type: ActionType.ChooseCreateRecommendedMeeting,
            });
          }}
        >
          <FontAwesomeIcon
            icon={faStar}
            className="me-2"
          />
          Create Meeting
        </button>

        {/* Import Meeting */}
        <button
          id="AttachZoomMeetingOrWebinarModal-manual-setup-button"
          type="button"
          className="btn btn-success progress-bar-striped btn-lg"
          aria-label="attach an existing meeting"
          onClick={() => {
            dispatch({
              type: ActionType.ChooseManuallyImport,
              isWebinar: false,
            });
          }}
        >
          <FontAwesomeIcon
            icon={faHeadset}
            className="me-2"
          />
          Import Existing Meeting
        </button>

        {/* Import Webinar */}
        <button
          id="AttachZoomMeetingOrWebinarModal-manual-setup-webinar"
          type="button"
          className="btn btn-success progress-bar-striped btn-lg"
          aria-label="attach an existing webinar manually"
          onClick={() => {
            dispatch({
              type: ActionType.ChooseManuallyImport,
              isWebinar: true,
            });
          }}
        >
          <FontAwesomeIcon
            icon={faVideo}
            className="me-2"
          />
          Import Existing Webinar
        </button>
      </div>
    );
  }

  /* --- Create Recommended Meeting --- */

  if (view === View.CreateRecommendedMeeting) {
    // Destructure state
    const {
      hostEmail,
    } = state;

    // Set title and body
    title = 'Create a New Zoom Meeting';
    body = (
      <div>
        {/* Input Field */}
        <div
          key="host-email"
          className="input-group mb-3"
        >
          <span className="input-group-text">
            <FontAwesomeIcon
              icon={faChalkboardTeacher}
              className="me-2"
            />
            Host Email
          </span>
          <input
            type="text"
            className="form-control"
            aria-label="email of the host"
            id="AttachZoomMeetingOrWebinarModal-host-email-field"
            value={hostEmail}
            placeholder="professor@harvard.edu"
            onChange={(e) => {
              dispatch({
                type: ActionType.UpdateHostEmail,
                hostEmail: e.target.value,
              });
            }}
          />
        </div>

        {/* Buttons */}
        <div
          key="buttons"
          className="text-center"
        >
          <button
            type="button"
            className="btn btn-secondary"
            aria-label="create and attach the meeting"
            id="AttachZoomMeetingOrWebinarModal-attach-and-create-meeting-button"
            onClick={async () => {
              // Validate
              const valid = isEmail(hostEmail);
              if (!valid) {
                return alert(
                  'Invalid Host Email',
                  'Please enter a valid email address for the host.',
                );
              }

              // Create the meeting
              createRecommended();
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="me-2"
            />
            Create Meeting
          </button>
        </div>
      </div>
    );
  }

  /* --------- Manually Import -------- */

  if (view === View.ManuallyImport) {
    // Destructure state
    const {
      isWebinar,
      zoomIdText,
    } = state;

    // Set title and body
    title = `What's the ${isWebinar ? 'Webinar' : 'Meeting'} ID?`;
    body = (
      <div>
        {/* Zoom ID */}
        <div className="input-group">
          <span className="input-group-text">
            {isWebinar ? 'Webinar' : 'Meeting'}
            {' '}
            ID
          </span>
          <input
            id="AttachZoomMeetingOrWebinarModal-meeting-id-field"
            type="text"
            className="form-control"
            aria-label={isWebinar ? 'webinar id' : 'meeting id'}
            value={zoomIdText}
            placeholder="e.g. 123456789"
            onChange={(e) => {
              dispatch({
                type: ActionType.UpdateZoomIdText,
                zoomIdText: e.target.value,
              });
            }}
          />
        </div>

        {/* Confirm Button */}
        <div className="mt-3 text-center">
          <button
            id="AttachZoomMeetingOrWebinarModal-save-button"
            type="button"
            className="btn btn-warning"
            aria-label={`attach the ${isWebinar ? 'webinar' : 'meeting'}`}
            onClick={async () => {
              // Parse meeting id
              const meetingId = parseMeetingId(zoomIdText);

              // ID must be valid
              if (!meetingId || String(meetingId).length < 9) {
                return alert(
                  'Invalid ID',
                  `Add a valid ${isWebinar ? 'Webinar' : 'Meeting'} ID to continue`,
                );
              }

              // Meeting id must not already be used
              for (let i = 0; i < otherEvents.length; i++) {
                const otherEvent = otherEvents[i];

                // Compare meetingId, ignore archived events
                if (
                  otherEvent.currentZoomId
                  && otherEvent.currentZoomId === meetingId
                  && !otherEvent.archived
                ) {
                  return alert(
                    'ID Already Used',
                    `That ${isWebinar ? 'Webinar' : 'Meeting'} ID is already being used by "${otherEvent.name}"`,
                  );
                }
              }

              // Ready to attach!
              attachExisting();
            }}
          >
            <FontAwesomeIcon
              icon={faSave}
              className="me-2"
            />
            Save
          </button>
        </div>
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <Modal
      title={title}
      size={ModalSize.Large}
      onTopOfOtherModals
      onClose={onCancel}
    >
      {body}
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AddZoomMeetingOrWebinarModal;
